<template>
  <div>
    <Setting @onHideSetting="goBack" :type="type" />
  </div>
</template>

<script>
import Setting from '@/views/approval/setting.vue';

export default {
  data() {
    return {};
  },
  props: {
    type: Number,
  },
  components: {
    Setting,
  },
  methods: {
    goBack() {
      this.$emit('onHideProcess');
    },
  },
};
</script>

<style lang="less" scoped>
</style>
