<template>
  <div style="position: relative" class="setting">
    <!-- <div class="fd-nav">
      <div class="fd-nav-left">
        <div class="fd-nav-back" @click="toReturn">
          <i class="anticon anticon-left"></i>
        </div>
        <div class="fd-nav-title">{{ workFlowDef.name }}</div>
      </div>
      <div class="fd-nav-center">
          <div class="fd-nav-container">
              <div class="ghost-bar" style="transform: translateX(300px);"></div>
              <div class="fd-nav-item"><span class="order-num">1</span>基础设置</div>
              <div class="fd-nav-item"><span class="order-num">2</span>表单设计</div>
              <div class="fd-nav-item active"><span class="order-num">3</span>流程设计</div>
              <div class="fd-nav-item"><span class="order-num">4</span>高级设置</div>
          </div>
      </div>
      <div class="fd-nav-right">
        <button type="button" class="ant-btn button-preview"><span>预 览</span></button>
        <button type="button" class="ant-btn button-publish" @click="saveSet">
          <span>发 布</span>
        </button>
      </div>
    </div> -->
    <div class="top">
      <div style="display: flex; align-items: center">
        <div class="left" @click="goBack">
          <i class="el-icon-arrow-left"></i>
        </div>
        <span style="text-indent: 10px" v-if="type === 10">请假设置</span>
        <span style="text-indent: 10px" v-if="type === 30">补卡设置</span>
        <span style="text-indent: 10px" v-if="type === 40">换班设置</span>
      </div>
      <div class="tabs" style="margin-left: -2%">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基础设置" name="base"></el-tab-pane>
          <el-tab-pane label="流程设置" name="process"></el-tab-pane>
        </el-tabs>
      </div>
      <el-button type="primary" round size="mini" @click="saveSet"
        >发布</el-button
      >
    </div>
    <div v-if="activeName === 'base'">
      <div class="form">
        <el-form :model="form" label-width="100px">
          <el-form-item label="异常处理">
            <div>审批节点内成员离职、为空等情况的处理方式</div>
            <el-radio-group
              v-model="form.exception_option"
              @change="handleChange"
            >
              <el-radio :label="10">自动同意</el-radio>
              <el-radio :label="20">转交给指定人员处理</el-radio>
              <el-button
                type="primary"
                v-if="form.exception_option === 20"
                size="mini"
                @click="personnelVisible = true"
                >{{ currPeople.realname ? "重新选择" : "选择人员" }}</el-button
              >
            </el-radio-group>
            <span style="margin-left: 10px; color: #f56c6c">{{
              currPeople.realname
            }}</span>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item label="自动审批">
            <div>当同一个审批人重复审批同一单据时</div>
            <el-radio-group v-model="form.repeat_option">
              <el-radio :label="10">仅首个节点需审批，其余自动同意</el-radio>
              <el-radio :label="20">仅连续审批时自动同意</el-radio>
              <el-radio :label="30">每个节点都需要审批</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="fd-nav-content" v-if="activeName === 'process'">
      <div class="dingflow-design">
        <!-- <div class="zoom">
          <div
            :class="'zoom-out' + (nowVal == 50 ? ' disabled' : '')"
            @click="zoomSize(1)"
          ></div>
          <span>{{ nowVal }}%</span>
          <div
            :class="'zoom-in' + (nowVal == 300 ? ' disabled' : '')"
            @click="zoomSize(2)"
          ></div>
        </div> -->
        <!-- <div
          class="box-scale"
          id="box-scale"
          :style="
            'transform: scale(' +
            nowVal / 100 +
            '); transform-origin: 50% 0px 0px;'
          "
        > -->
        <div
          class="box-scale"
          id="box-scale"
          :style="'transform: none; transform-origin: 50% 0px 0px;'"
        >
          <!-- 流程组件 -->
          <nodeWrap
            :type="type"
            :nodeConfig.sync="nodeConfig"
            :flowPermission.sync="flowPermission"
            :isTried.sync="isTried"
            :directorMaxLevel="directorMaxLevel"
            :tableId="tableId"
            @onHideSca="hideSca = true"
          ></nodeWrap>
          <!-- 流程结束 -->
          <div class="end-node">
            <div class="end-node-circle"></div>
            <div class="end-node-text">流程结束</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 条件不完善的弹窗 -->
    <el-dialog title="提示" :visible.sync="tipVisible">
      <div class="ant-confirm-body">
        <i class="anticon anticon-close-circle" style="color: #f00"></i>
        <span class="ant-confirm-title">当前无法发布</span>
        <div class="ant-confirm-content">
          <div>
            <p class="error-modal-desc">以下内容不完善，需进行修改</p>
            <div class="error-modal-list">
              <div
                class="error-modal-item"
                v-for="(item, index) in tipList"
                :key="index"
              >
                <div class="error-modal-item-label">流程设计</div>
                <div class="error-modal-item-content">
                  {{ item.name }} 未选择{{ item.type }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tipVisible = false">我知道了</el-button>
        <el-button type="primary" @click="tipVisible = false"
          >前往修改</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="指定处理人员"
      :visible.sync="personnelVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="height: 400px; overflow-y: auto">
        <el-radio-group v-model="form.exception_user" class="radio-group">
          <template v-for="item in peopleList">
            <el-radio :key="item.store_user_id" :label="item.store_user_id">
              <div style="display: flex; align-items: center">
                <img
                  :src="item.avatar"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    border-radius: 50%;
                  "
                />
                <span>{{ item.realname }}</span>
              </div>
            </el-radio>
          </template>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from "good-storage";
import { ListRequest } from "@/api/authority/menage";
import { createReq, getDetailReq } from "@/api/work/examine";

export default {
  components: {},
  data() {
    const peopleImg = require("@/assets/images/icon_people.png");
    return {
      work_flow_id: null,
      currPeople: {},
      peopleList: [],
      peopleImg,
      personnelVisible: false,
      chooseMember: false, // 选择人员的按钮
      form: {},
      activeName: "base",
      hideSca: false,
      isTried: false,
      tipList: [],
      tipVisible: false,
      nowVal: 100,
      processConfig: {},
      nodeConfig: {},
      workFlowDef: {},
      flowPermission: [],
      directorMaxLevel: 0,
      dialogVisible: true,
      tableId: "",
    };
  },
  props: {
    type: Number,
  },
  created() {
    this.getList();
    // this.$axios
    //   .get(`${process.env.BASE_URL}data.json`, {
    //     workFlowDefId: this.$route.params.workFlowDefId,
    //   })
    //   .then((res) => {
    //     this.processConfig = res.data;
    //     this.nodeConfig = this.processConfig.nodeConfig;
    //     this.flowPermission = this.processConfig.flowPermission;
    //     this.directorMaxLevel = this.processConfig.directorMaxLevel;
    //     this.workFlowDef = this.processConfig.workFlowDef;
    //     this.tableId = this.processConfig.tableId;
    //   });
  },
  methods: {
    getDetail() {
      getDetailReq({ type: this.type }).then((res) => {
        if (res.code === 1) {
          if (res.data) {
            if (res.data.nodeinfo) {
              const node = {
                tableId: 1,
                workFlowVersionId: "",
                workFlowDef: {
                  name: "审批设置",
                  publicFlag: 1,
                  sortNo: 5,
                  duplicateRemovelFlag: 1,
                  optionTip: "",
                  optionNotNull: 0,
                  status: 1,
                },
                directorMaxLevel: 10,
                flowPermission: [],
                nodeConfig: res.data.nodeinfo,
              };
              this.processConfig = node;
              this.nodeConfig = this.processConfig.nodeConfig;
              this.flowPermission = this.processConfig.flowPermission;
              this.directorMaxLevel = this.processConfig.directorMaxLevel;
              this.workFlowDef = this.processConfig.workFlowDef;
              this.tableId = this.processConfig.tableId;
              this.form = {
                exception_option: res.data.exception_option,
                exception_user: res.data.exception_user,
                repeat_option: res.data.repeat_option,
                work_flow_id: res.data.work_flow_id,
              };
              if (this.form.exception_user) {
                this.currPeople = this.peopleList.filter(
                  (item) => item.store_user_id === this.form.exception_user
                )[0];
              }
              this.work_flow_id = res.data.work_flow_id;
              console.log(JSON.stringify(res.data.nodeinfo));
            }
          } else {
            const node = {
              tableId: 1,
              workFlowVersionId: "",
              workFlowDef: {
                name: "审批设置",
                publicFlag: 1,
                sortNo: 5,
                duplicateRemovelFlag: 1,
                optionTip: "",
                optionNotNull: 0,
                status: 1,
              },
              directorMaxLevel: 10,
              flowPermission: [],
              nodeConfig: {
                pkId: "sid-start-node",
                nodeName: "发起人",
                type: 0,
                priorityLevel: "",
                settype: "",
                selectMode: "",
                selectRange: "",
                examineRoleId: "",
                directorLevel: "",
                replaceByUp: "",
                examineMode: "",
                noHanderAction: "",
                examineEndType: "",
                examineEndRoleId: "",
                examineEndDirectorLevel: "",
                ccSelfSelectFlag: "",
                conditionList: [],
                nodeUserList: [],
                childNode: null,
                conditionNodes: [],
              },
            };
            this.processConfig = node;
            this.nodeConfig = this.processConfig.nodeConfig;
            this.flowPermission = this.processConfig.flowPermission;
            this.directorMaxLevel = this.processConfig.directorMaxLevel;
            this.workFlowDef = this.processConfig.workFlowDef;
            this.tableId = this.processConfig.tableId;
          }
        }
      });
    },
    handleTrue() {
      this.personnelVisible = false;
      this.currPeople = this.peopleList.filter(
        (item) => item.store_user_id === this.form.exception_user
      )[0];
    },
    getList() {
      const hierarchy_id = storage.get("userInfo").hierarchy_id;
      ListRequest({
        hierarchy_ids: [hierarchy_id],
      }).then((res) => {
        if (res.code === 1) {
          this.peopleList = res.data.list;
          this.getDetail();
        }
      });
    },
    handleClose() {
      this.personnelVisible = false;
    },
    handleChange(val) {
      if (val === 20) {
        this.chooseMember = true;
      } else {
        this.chooseMember = false;
      }
    },
    goBack() {
      this.$emit("onHideSetting");
    },
    toReturn() {
      //window.location.href = ""
    },
    reErr(data) {
      if (data.childNode) {
        if (data.childNode.type == 1) {
          //审批人
          if (data.childNode.error) {
            this.tipList.push({
              name: data.childNode.nodeName,
              type: "审核人",
            });
          }
          this.reErr(data.childNode);
        } else if (data.childNode.type == 2) {
          if (data.childNode.error) {
            this.tipList.push({
              name: data.childNode.nodeName,
              type: "抄送人",
            });
          }
          this.reErr(data.childNode);
        } else if (data.childNode.type == 3) {
          this.reErr(data.childNode.childNode);
        } else if (data.childNode.type == 4) {
          this.reErr(data.childNode);
          for (var i = 0; i < data.childNode.conditionNodes.length; i++) {
            if (data.childNode.conditionNodes[i].error) {
              this.tipList.push({
                name: data.childNode.conditionNodes[i].nodeName,
                type: "条件",
              });
            }
            this.reErr(data.childNode.conditionNodes[i]);
          }
        }
      } else {
        data.childNode = null;
      }
    },
    saveSet() {
      this.isTried = true;
      this.tipList = [];
      this.reErr(this.nodeConfig);
      if (this.tipList.length !== 0) {
        this.tipVisible = true;
        this.activeName = "process";
        return;
      }
      this.processConfig.flowPermission = this.flowPermission;
      if (!this.form.exception_option) {
        this.$message.error("请选择异常处理类型");
        this.activeName = "base";
        return;
      }
      if (this.form.exception_option === 20 && !this.form.exception_user) {
        this.$message.error("请选择异常处理人员");
        this.activeName = "base";
        return;
      }
      if (!this.form.repeat_option) {
        this.$message.error("请选择自动审批类型");
        this.activeName = "base";
        return;
      }
      this.form = {
        ...this.form,
        type: this.type,
        node_data: this.processConfig,
      };
      if (this.work_flow_id) {
        this.form.work_flow_id = this.work_flow_id;
      }
      createReq(this.form).then((res) => {
        if (res.code === 1) {
          this.$message.success("设置成功");
        }
      });
      console.log(JSON.stringify(this.processConfig));
      // this.$axios.post("", this.processConfig).then(res => {
      //     if (res.code == 200) {
      //         this.$message.success("设置成功");
      //         setTimeout(function () {
      //             window.location.href = ""
      //         }, 200)
      //     }
      // })
    },
    zoomSize(type) {
      if (type == 1) {
        if (this.nowVal == 50) {
          return;
        }
        this.nowVal -= 10;
      } else {
        if (this.nowVal == 300) {
          return;
        }
        this.nowVal += 10;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../css/workflow.less";
.radio-group {
  .el-radio {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.error-modal-list {
  width: 455px;
}
.form {
  padding: 20px;
  background: white;
  margin-top: 10px;
}
.top {
  background: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  .left {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #eee;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  .tabs {
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__nav-wrap::after {
      background-color: white;
    }
  }
}
</style>
