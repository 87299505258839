<template>
  <div class="content" v-if="!showProcess">
    <div class="item" @click="handleShowProcess(10)">
      <div class="img">
        <img :src="img1" />
      </div>
      <div class="txt">请假设置</div>
    </div>
    <div class="item" @click="handleShowProcess(30)">
      <div class="img">
        <img :src="img2"  />
      </div>
      <div class="txt">补卡设置</div>
    </div>
    <div class="item" @click="handleShowProcess(40)">
      <div class="img">
        <img :src="img3"  />
      </div>
      <div class="txt">换班设置</div>
    </div>
  </div>
  <Process v-else @onHideProcess="showProcess = false" :type="type" />
</template>

<script>
import Process from './Process.vue';

export default {
  data() {
    const img1 = require('@/assets/images/system/icon_leave.png');
    const img2 = require('@/assets/images/system/icon_card_replacement.png');
    const img3 = require('@/assets/images/system/icon_shift_change.png');
    return {
      img1,
      img2,
      img3,
      showProcess: false,
      type: 10,
    };
  },
  methods: {
    handleShowProcess(type) {
      this.type = type;
      this.showProcess = true;
    },
  },
  components: {
    Process,
  },
};
</script>

<style lang="less" scoped>
.content {
  background: white;
  width: 100%;
  height: calc(100vh - 85px);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  border-radius: 6px;
  border: 1px solid #ddd;
  .item {
    cursor: pointer;
    margin-right: 30px;
    height: 120px;
    .img {
      width: 50px;
      height: 50px;
      background: #eee;
      margin: 0 auto;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        }
      }
    .txt {
      text-align: center;
    }
  }
}
</style>
